.shipping-modal__title {
    font-size: calc(26rem/16);
    color: var(--color-mid-grey);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
    }
}

.shipping-modal__body{
    min-height: calc(400rem/16);
}